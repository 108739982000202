import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import { TextField, Button, CircularProgress } from '@material-ui/core';
import validator from 'validator';

import SEO from '../components/seo';

import * as AccountActions from '../actions/account-actions';

import styles from '../styles/login.module.scss';

const ForgotPasswordPage = ({ forgotPassword }) => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [errors, setErrors] = useState(null);
    const [inputErrors, setInputErrors] = useState({});
    const [values, setValues] = useState(
        {
            email: ''
        }
    );

    const handleChange = (e) => {
        if (e.target) {
            const { name, value } = e.target;

            setValues(state => ({
                ...state,
                [name]: value
            }));

            if (inputErrors[name]) {
                setInputErrors(state => ({
                    ...state,
                    [name]: null
                }));
            }
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        const { email } = values;

        const errs = {};

        if (validator.isEmpty(email)) {
            errs['email'] = 'Email Required';
        } else if (!validator.isEmail(email)) {
            errs['email'] = 'Invalid Email';
        }

        if (Object.keys(errs).length) {
            setInputErrors(errs);
            return false;
        }

        setLoading(true);
        setSuccess(null);
        setErrors(null);

        const result = await forgotPassword(
            {
                email
            }
        );

        setLoading(false);

        if (result.ok) {
            setSuccess(['We sent you an email to reset your password.']);

            return true;
        }

        setErrors([
            'Sorry, an error occurred.',
            'Please try again.'
        ]);

        return false;
    };

    return (
        <>
            <SEO
                title='Forgot Password'
                path='/forgot-password/'
                description='Forgot your password? Use this form to reset it.'
            />
            <section className={styles.main}>
                <div className={styles.content}>
                    <form
                        noValidate
                        onSubmit={onSubmit}
                    >
                        <h1>Forgot Password</h1>
                        <p>Need to reset your password?</p>
                        {success &&
                            <ul className={styles.success}>
                                {success.map((x, i) => (
                                    <li key={i}>{x}</li>
                                ))}
                            </ul>
                        }
                        {errors &&
                            <ul className={styles.errors}>
                                {errors.map((x, i) => (
                                    <li key={i}>{x}</li>
                                ))}
                            </ul>
                        }
                        <TextField
                            id='email'
                            name='email'
                            label={inputErrors['email'] || 'Email'}
                            error={Boolean(inputErrors['email'])}
                            type='email'
                            autoComplete='email'
                            value={values.email}
                            onChange={handleChange}
                            margin='normal'
                            fullWidth={true}
                            className={styles.email}
                        />
                        <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            fullWidth={true}
                            className={styles.signin}
                            disabled={loading}
                        >
                            {loading ?
                                <CircularProgress
                                    color='inherit'
                                    size={24}
                                    className={styles.loading}
                                /> :
                                'SUBMIT'
                            }
                        </Button>
                        <Button
                            type='button'
                            variant='text'
                            component={Link}
                            to='/login/'
                            size='small'
                            color='secondary'
                            className={styles.forgot}
                        >
                            Login Instead
                        </Button>
                    </form>
                </div>
            </section>
        </>
    )
};

const mapDispatch = { forgotPassword: AccountActions.forgotPassword };

export default connect(null, mapDispatch)(ForgotPasswordPage);
